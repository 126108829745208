@import ../../styles/helpers

.inner
    position: relative

.head
    margin-bottom: 48px
    padding-right: 120px
    +m
        padding-right: 0

.info
    margin-bottom: 32px
    +body-2
    color: $n4


.wrapper
    margin: 0 -16px

.button
    margin-top: 48px