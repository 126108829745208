@import ../../styles/helpers

.item
    display: none
    &:first-child
        display: block

.title
    text-align: center

.info
    margin-top: 12px
    text-align: center
    +body-2
    color: $n4

.btns
    display: flex
    justify-content: center
    margin-top: 32px
    .button
        &:not(:last-child)
            margin-right: 12px

.note
    margin-top: 32px
    text-align: center
    +caption-2
    color: $n4

.form
    margin-top: 32px
    & > .button
        width: 100%
        margin-top: 12px

.foot
    margin-top: 32px
    text-align: center
    +caption-2
    color: $n3

.link,
.password
    font-weight: 600
    transition: color .2s

.link
    color: $p1
    &:hover
        color: darken($p1, 10)

.password
    color: $n3
    &:hover
        color: $n1

.variants
    display: flex
    flex-direction: column
    margin-bottom: 20px

.radio
    &:not(:last-child)
        margin-bottom: 16px
        padding-bottom: 16px
        border-bottom: 1px solid $n6

.code
    display: flex
    justify-content: center

.number
    flex-shrink: 0
    width: 48px
    input
        width: 100%
        height: 64px
        border-radius: 8px
        border: 1px solid $n7
        background: $n7
        text-align: center
        +dm-sans
        font-size: 32px
        font-weight: 600
        color: $n2
        transition: all .2s
        &:focus
            border-color: $n6
            background: $n8
    &:not(:last-child)
        margin-right: 16px
    