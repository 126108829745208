@import ../../styles/helpers

.modal
  display: flex
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  padding: 48px
  background: rgba($n1,.3)
  opacity: 0
  animation: showModal .4s forwards
  overflow: auto
  z-index: 999
  +m
    padding: 32px 16px

@keyframes showModal
  0%
    opacity: 0
  100%
    opacity: 1

.outer
  position: relative
  width: 100%
  max-width: 544px
  margin: auto
  background: $n8
  border-radius: 16px
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08)
  z-index: 2
  & > div
    padding: 72px 96px
    +m
      padding: 32px 24px

.close
  position: absolute
  top: -16px
  right: -16px
  width: 32px
  height: 32px
  background: $n8
  border-radius: 50%
  box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.1)
  font-size: 0
  svg
    fill: $n4
    transition: transform .2s
  &:hover
    svg
      transform: rotate(90deg)
