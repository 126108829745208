@import ../../../styles/helpers

.section
    +d
        margin-bottom: 96px
    +m
        margin-bottom: 64px

.container
    max-width: 1440px
    +w
        padding: 0 40px
    +t
        padding: 0
    +m
        padding: 0 8px

.preview
    position: relative
    &:before
        content: ""
        display: block
        padding-bottom: 60.63%
        +d
            padding-bottom: 64%
        +m
            display: none
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 70%
        border-radius: 24px
        object-fit: cover
        +t
            border-radius: 0
        +m
            position: static
            height: 780px
            border-radius: 16px 16px 0 0
            object-position: 50% 100%
    
.wrap
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    padding: 145px 80px
    +d
        padding: 98px 80px
    +t
        padding: 80px 40px
    +m
        padding: 80px 32px
        text-align: center

.title
    max-width: 450px
    margin-bottom: 16px

.info
    margin-bottom: 24px
    color: $n2
    +d
        font-size: 16px

.panel
    margin: -175px 80px 0
    +w
        margin: -175px 40px 0
    +d
        margin-top: -148px
    +t
        margin: -110px 16px 0
    +m
        margin: -180px 8px 0

.body
    margin-top: 24px
    +d
        margin-top: 16px

.row
    display: flex
    +x
        margin: 0 -20px
    +t
        margin: 0
    +m
        display: block
    & > div
        &:first-child
            flex: 0 0 300px
            +w
                flex: 0 0 270px
            +d
                flex: 0 0 250px
            +t
                flex: 0 0 220px
        &:nth-child(2)
            flex-grow: 1
        &:nth-child(3)
            flex: 0 0 296px
            +d
                flex: 0 0 240px
            +t
                flex: 0 0 170px
