@import ../../styles/helpers

.head
    margin-bottom: 24px
    padding-bottom: 24px
    border-bottom: 1px solid $n6

.title
    margin-bottom: 4px

.info
    margin-bottom: 32px
    +body-2
    color: $n4

.subtitle
    margin-bottom: 8px
    +body-bold-2

.author
    display: flex
    align-items: center
    .text
        margin-right: 4px
        +caption-2
        color: $n4
    
.avatar
    flex-shrink: 0
    width: 24px
    height: 24px
    margin-right: 8px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.man
    +caption-bold-2

.line
    display: flex
    flex-wrap: wrap
    align-items: center
    margin-bottom: 32px

.rating
    display: flex
    align-items: center
    +m
        flex-wrap: wrap
    svg
        margin-right: 8px
        fill: $s3
    
.number
    font-weight: 500

.reviews
    margin-left: 8px
    color: $n4

.parameters
    display: flex
    flex-wrap: wrap
    margin-left: 24px
    +m
        flex: 0 0 100%
        margin: 16px 0 0

.parameter
    +caption-2
    color: $n4
    svg
        margin-right: 8px
        fill: $n4
    &:not(:last-child)
        margin-right: 20px

.list
    display: flex
    margin-bottom: 32px
    +m
        display: block

.item
    display: flex
    align-items: center
    flex: 0 0 50%
    &:first-child
        padding-right: 24px
        +m
            padding-right: 0
    &:not(:last-child)
        +m
            margin-bottom: 16px

.icon
    display: flex
    justify-content: center
    align-items: center
    width: 48px
    height: 48px
    margin-right: 16px
    border-radius: 50%
    border-width: 2px
    border-style: solid
    svg
        fill: $n2

.category
    +caption-2
    color: $n4

.value
    +body-bold-2

.stage
    margin-bottom: 32px
    +body-bold-1

.table
    margin-bottom: 32px

.row
    display: flex
    align-items: center
    &:not(:last-child)
        margin-bottom: 20px

.cell
    flex: 0 0 50%
    +m
        flex: 0 0 auto
    &:first-child
        display: flex
        align-items: center
        padding-right: 24px
        color: $n4
        +m
            margin-right: auto
        svg
            margin-right: 12px
            fill: $n4
    &:nth-child(2)
        font-weight: 500
    
.btns
    display: flex
    .button
        +m
            padding: 0 16px
        &:not(:last-child)
            margin-right: 12px
