@import ../../styles/helpers

.control
    display: flex
    align-items: center
    margin-bottom: 24px
    padding: 16px 0

.button
    margin-right: auto

.breadcrumbs
    margin-left: 16px
