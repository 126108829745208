@import ../../styles/helpers

.container
    max-width: 1440px
    +w
        padding: 0 40px
    +t
        padding: 0

.inner
    padding: 0px 80px
    background: $n7
    border-radius: 24px
    +w
        padding: 80px 64px
    +x
        padding: 64px 40px
    +t
        border-radius: 0
    +m
        padding: 64px 24px


.head
    margin-bottom: 80px
    text-align: center
    +d
        margin-bottom: 64px
    +m
        margin-bottom: 40px

.title
    margin-bottom: 12px

.slider
    display: flex
    flex-wrap: wrap
    justify-content: center
    margin: -64px -10px 0
    +x
        margin: -48px -16px 0
    +m
        display: block
        margin: 0

.item
    flex: 0 0 calc(20% - 48px)
    width: calc(20% - 48px)
    margin: 5px 24px 5px
    padding: 5px
    +x
        flex: 0 0 calc(20% - 32px)
        width: calc(20% - 32px)
        margin: 48px 16px 0
    +d
        flex: 0 0 calc(25% - 32px)
        width: calc(25% - 32px)
    +t
        flex: 0 0 calc(33.333% - 32px)
        width: calc(33.333% - 32px)
    +m
        display: block
        width: 100%
        margin: 0
    &:nth-child(n+8)
        +d
            display: none
        +t
            display: block