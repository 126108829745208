@import ../../styles/helpers

.control
    margin-bottom: 80px
    +t
        margin-bottom: 48px

.wrapper
    display: flex
    align-items: flex-start
    +t
        flex-direction: column-reverse

.confirm
    flex-grow: 1
    padding-right: 80px
    +x
        padding-right: 48px
    +d
        padding-right: 32px
    +t
        width: 100%
        padding: 0

.price
    flex-shrink: 0
    width: 544px
    +x
        width: 500px
    +d
        width: 416px
    +t
        width: 100%
        margin-bottom: 48px

